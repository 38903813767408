import classes from "./WhyRatezip.module.css";
import compareLoans from "../../../assets/images/compare_loan_img.webp";

function WhyRatezip() {
  return (
    <>
      <div className={classes.container}>
        <h2>Why Us?</h2>
        <ul>
          <li>Top offers from leading lenders</li>
          <li>Compare rates</li>
          <li>Buy a home</li>
        </ul>
      </div>
      <div className={classes.container}>
        <h2>Top Brands</h2>
        <img src={compareLoans} alt="compare loans" />
      </div>
    </>
  );
}

export default WhyRatezip;
