import classes from './MainContent.module.css'
import EditorialContent from "./EditorialContent";
import Headlines from "./Headlines";
import Listings from "./Listings";

function MainContent() {

    return(
        <div className={classes.container}>
            <Headlines />
            <Listings />
            <EditorialContent />
        </div>
    )
}

export default MainContent;