import classes from "./VisitsBox.module.css";
import bankAccountImg from "../../../assets/images/menu_savings_icon.png";

function VisitsBox() {
  return (
    <div className={classes.container}>
      <img loading="lazy" src={bankAccountImg} alt="savings pig" />
      <h6>14,000+ Visitors</h6>
      <p>picked a lender with FHA.com last week.</p>
    </div>
  );
}

export default VisitsBox;
