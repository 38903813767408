import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { useState } from "react";
import classes from "./ListItem.module.css";
import StarRating from "./StarRating";

const ListItem = ({
  id,
  name,
  logo,
  rating,
  nmls,
  headline,
  bullets,
  link,
  nmlsBlurb,
  nmlsBlurb2,
}) => {
  const [showArrow, setShowArrow] = useState(false);

  const handleToggleButtonArrowIcon = () => {
    setShowArrow(!showArrow);
  };

  const handleTraceInfo = (event) => {
    event.preventDefault();

    window.dataLayer.push({
      ni_lender: name,
      product_name: "Mortgage",
      rank_order: id,
      event: "fhaMortgageTableClick",
    });

    window.open(link, "_blank");
  };

  return (
    <div className={classes.container} key={id}>
      <div className={classes.mainContent}>
        <div className={classes.leftContainer}>
          <div className={classes.logoContainer}>
            <img
              loading="lazy"
              src={`https://www.ratezip.com/images/${logo}`}
              alt={`${name}`}
            />
          </div>
          <p>NMLS #{nmls}</p>
          <StarRating rating={rating} />
        </div>
        <div className={classes.middleContainer}>
          <div className={classes.middleContent}>
            <h3>{name}</h3>
            {headline.length > 0 && <h4>{headline}</h4>}
            <ul>
              {bullets.map((bullet, index) => (
                <li key={index}>
                  <span className={classes.checkmark}>&#10003;</span>
                  {bullet}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={classes.rightContainer}>
          <Button
            onMouseEnter={handleToggleButtonArrowIcon}
            onMouseLeave={handleToggleButtonArrowIcon}
            endIcon={showArrow ? <ArrowForwardIcon /> : null}
            onClick={handleTraceInfo}
            sx={{
              backgroundColor: "#2da83e !important",
              borderRadius: "4px",
              textTransform: "uppercase",
              fontFamily: "Outfit, sans-serif",
              width: "140px",
              height: "50px",
              transition: "0.5s",
              color: "#fff",
              "&:hover": {
                boxShadow: "0px 0px 0px 5px rgba(0, 0, 0, 0.2)",
                transition: "0.5s",
              },
            }}
          >
            View Rates
          </Button>
        </div>
      </div>
      <hr className={classes.mainSpacer} />
      {nmlsBlurb && (
        <div className={classes.nmlsBlurb}>
          <p>{nmlsBlurb}</p>
          {nmlsBlurb2 && <p>{nmlsBlurb2}</p>}
        </div>
      )}
    </div>
  );
};

export default ListItem;
