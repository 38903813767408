import Tooltip from "@mui/material/Tooltip";

import star_mask from "../../assets/images/star_mask.png";
import classes from "./StarRating.module.css";

function StarRating({ rating }) {
  if (!rating) {
    return <></>;
  }

  const calculatedWidth = (rating / 5) * 50;

  const scoreShown = (rating * 2).toFixed(1);

  return (
    <div className={classes.container}>
      <div className={classes.starsText}>
        <Tooltip
          title={
            "RateZip scores are objectively determined by our editorial team. Our scoring formula weighs several factors consumers should consider when choosing financial products and services."
          }
        >
          <p>{scoreShown}</p>
        </Tooltip>
      </div>
      <div className={classes.starsContainer}>
        <img loading="lazy" src={star_mask} alt={"star_mask"} />
        <div
          className={classes.starFill}
          style={{
            width: `${calculatedWidth}px`,
            backgroundColor: "#339cde",
            zIndex: "2",
          }}
        />
        <div
          className={classes.starFill}
          style={{
            width: "50px",
            backgroundColor: "#e9f5ff",
            zIndex: "1",
          }}
        />
      </div>
    </div>
  );
}

export default StarRating;
