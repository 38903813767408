import classes from './Spinner.module.css'

const Spinner = ({ text }) => {
    const spinnerText = text || "Loading Offers..."

    return (
        <>
            <div className={classes.loader} />
            <p className={classes.spinnerText}>{spinnerText}</p>
        </>
    )
}

export default Spinner;