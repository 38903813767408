import { createContext, useState, useEffect } from "react";
import ListItem from "../components/ListItem/ListItem";

// import additionalOffers
import { additionalOffers } from "../util/additional-offers";

const AppContext = createContext({
  isLoading: true,
  hasError: false,
  listings: null,
  modalIsOpen: false,
  learnMoreModalIsOpen: false,
  showStatic: false,
  handleOpen: () => {},
  handleClose: () => {},
  handleLearnMoreOpen: () => {},
  handleLearnMoreClose: () => {},
});

export const AppContextProvider = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [listings, setListings] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    // get the query string
    const queryString = window.location.search;

    setListings(
      additionalOffers.map((i, index) => {
        return (
          <ListItem
            key={index + 1}
            id={index + 1}
            logo={i.logo}
            name={i.lenderName}
            rating={i.rating}
            nmls={i.nmlsNumber}
            headline={i.headline}
            bullets={i.bullets}
            link={i.link + queryString}
            nmlsBlurb={i.nmlsBlurb}
            nmlsBlurb2={i.nmlsBlurb2}
          />
        );
      })
    );
    setIsLoading(false);
  }, []);

  return (
    <AppContext.Provider
      value={{
        isLoading: isLoading,
        listings: listings,
        modalIsOpen: modalIsOpen,
        handleClose: handleCloseModal,
        handleOpen: handleOpenModal,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContext;
