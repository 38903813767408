import classes from './AdvertisingDisclosure.module.css'
import { useContext } from 'react';
import AppContext from '../../store/app-context';

function AdvertisingDisclosure() {
    const { handleOpen } = useContext(AppContext)

    return(
        <div className={classes.container}>
            <p
                onClick={handleOpen}
                className={"advertising_modal"}
            >
                Advertising Disclosure
            </p>
        </div>
    )
}

export default AdvertisingDisclosure