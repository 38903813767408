import AdvertisingDisclosure from "./AdvertisingDisclosure";
import logo from "../../assets/images/logo.png";
import classes from "./Header.module.css";

function Header() {
  return (
    <div className={classes.appHeader}>
      <div className={classes.headerContainer}>
        <img alt="logo" src={logo} className={classes.appLogo} />
        <AdvertisingDisclosure />
      </div>
    </div>
  );
}

export default Header;
