import { lazy } from "react";

import Header from "./components/Header/Header";
import MainContent from "./components/MainContent/MainContent";

const Modal = lazy(() => import("./components/Modal/Modal"));
const Footer = lazy(() => import("./components/Footer/Footer"));

function App() {
  return (
    <div className="app-wrapper">
      <Modal />
      <Header />
      <MainContent />
      <Footer />
    </div>
  );
}

export default App;
