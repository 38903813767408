import month from "../../constant/month.json";
import classes from "./Headlines.module.css";

import bankIcon from "../../assets/images/bank_icon.png";

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

function Headlines() {
  return (
    <>
      <div className={classes.headerText}>
        <h2>
          <span>Top Mortgage Offers</span> from Our Partners -{" "}
          {month[currentMonth]} {currentYear}
        </h2>
      </div>
      <div className={classes.subheaderText}>
        <img loading="lazy" src={bankIcon} alt="bank icon" />
        <h4>
          Compare Today's Mortgage Offers to Find the Right Mortgage for You!
        </h4>
      </div>
      <div className={classes.subheaderText}>
        <img loading="lazy" src={bankIcon} alt="bank icon" />
        <h4>Getting a Quote takes Just a Few Minutes.</h4>
      </div>
    </>
  );
}

export default Headlines;
