export const additionalOffers = [
  {
    headline: "",
    bullets: [
      "View offers in minutes",
      "Low down payment options available",
      "Expanded credit requirements",
      "Compare rates today",
    ],
    lenderName: "Bankrate",
    nmlsNumber: "1427381",
    nmlsBlurb: "Bankrate, LLC NMLS ID# 1427381",
    nmlsBlurb2: "BR Tech Services, Inc. NMLS ID #1743443",
    logo: "Bankrate.png",
    rating: 5,
    link: "https://www.ratezip.com/rate-quotes/redirects/rv/mortgage/",
  },
  {
    headline: "Get personalized lender recommendations for $0",
    bullets: [
      "Flexible Mortgage Options for 580+ FICO",
      "Home Buying with as little as 1% down",
      "Compare lenders 100% online",
      "First-time home buyer programs",
    ],
    lenderName: "Quicken Loans",
    nmlsNumber: "167283",
    logo: "quicken_loans.svg",
    rating: 4.5,
    link: "https://www.ratezip.com/rate-quotes/redirects/quicken/purchase/",
  },
  {
    headline: "FHA loan experts available",
    bullets: [
      "Low Rates: Quick Quote & Approval",
      "Rate Lock Protection, Lock Now Before Rates Go Up",
      "Pre-Approval Letter with Rate Lock Protection",
      "Over $100 Billion Funded. 21 Years in Business",
    ],
    lenderName: "AmeriSave Mortgage",
    nmlsNumber: "1168",
    logo: "amerisave.svg",
    rating: 4.5,
    link: "https://www.ratezip.com/rate-quotes/redirects/as/purchase/",
  },
  {
    headline: "Headline: Check FHA Loan Eligibility Today",
    bullets: [
      "FHA Loans at 3.5% Down with Flexible Credit Requirements",
      "Perfect For First-Time Home Buyers",
      "A BBB rating with 317k+ positive reviews",
      "Get A Free No-Obligation Quote Now",
    ],
    lenderName: "New American Funding",
    nmlsNumber: "6606",
    logo: "naf_logo.svg",
    rating: 4,
    link: "https://www.ratezip.com/rate-quotes/redirects/naf/purchase-device/",
  },
];
