import classes from "./Listings.module.css";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import VisitsBox from "./Sidebar/VisitsBox";
import WhyRatezip from "./Sidebar/WhyRatezip";

import Spinner from "../Spinner/Spinner";
import { useContext } from "react";
import AppContext from "../../store/app-context";

function Listings() {
  const { listings, hasError, isLoading } = useContext(AppContext);

  let content = <Spinner />;

  if (hasError) {
    content = <h1 style={{ margin: "5rem auto" }}>No Offers Found</h1>;
  }

  if (!isLoading && listings) {
    content = (
      <>
        <div className={classes.featured}>
          <h2>Sponsored offers</h2>
          <Tooltip
            title={
              "Offers featured here have been sponsored, which impacts how, where, and in what order the products appear. Not all offers are displayed on this page."
            }
          >
            <InfoOutlinedIcon fontSize="small" color="primary" />
          </Tooltip>
        </div>
        <p>
          These are sponsored offers rated highly by us for competitive rates,
          favorable loan terms, and excellent customer service.
        </p>
        <div className={classes.resultList}>{listings}</div>
      </>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>{content}</div>
      <div className={classes.rightColumn}>
        <VisitsBox />
        <WhyRatezip />
      </div>
    </div>
  );
}

export default Listings;
